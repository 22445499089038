import React, { useEffect } from "react";
import {
  useCall,
  useCallStateHooks,
  ParticipantView,
  CallControls,
  SpeakerLayout,
  StreamTheme,
  ScreenShareButton,
  ToggleAudioPreviewButton,
  ToggleAudioPublishingButton,
  ToggleAudioOutputButton,
  ToggleVideoPublishingButton,
  CallStatsButton,
} from "@stream-io/video-react-sdk";

// add styles for the video UI
import "@stream-io/video-react-sdk/dist/css/styles.css";
import { toast } from "react-toastify";
import { notifyStatusChange } from "../Api";
import Participants from "../Components/Participants";
import ChatComponent from "../Components/ChatComponent";

export const LivestreamUI = ({ token }) => {
  const call = useCall();
  const {
    useIsCallLive,
    useLocalParticipant,
    useParticipantCount,
    useCallCallingState,
    // ... more hooks
  } = useCallStateHooks();
  const totalParticipants = useParticipantCount();
  const localParticipant = useLocalParticipant();
  const isCallLive = useIsCallLive();

  
  // call.camera.selectTargetResolution({
  //   height: 2532,
  //   width: 800,
  // })
  useEffect(() => {
    call?.camera?.selectTargetResolution({
      width: 780,
      height: 1200,
    });

  }, []);

  async function notify() {
    // notifica todos os participantes da call
    console.log("Notificando todos os participantes da call");
    let status = isCallLive ? "live" : "idle";
    let response = await notifyStatusChange(call.id, status, token);
    console.log(response);
    toast.success("A transmissão está ao vivo!");
  }
  async function endCall() {
    await call?.endCall();
    window.location.href = "/";
  }

  useEffect(() => {
    if (isCallLive) {
      notify();
    }
  }, [isCallLive]);

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
      <div className="call-participants">
        {totalParticipants} participantes (incluindo você)
      </div>
      <div style={{ flex: 1 }}>
        {localParticipant && (
          <StreamTheme>
            <div className="side-by-side">
              <div className="video-column">
                <ParticipantView
                  participant={localParticipant}
                  className="local-participant"
                />
              </div>
              <div className="chat-column">
                <ChatComponent channel={call.id} user={localParticipant} />
              </div>
              <div className="participant-column">
                <Participants />
              </div>
            </div>
            <div className="call-control">
              <ScreenShareButton />
              <div>|</div>
              <ToggleAudioOutputButton />
              <div>|</div>
              <ToggleAudioPublishingButton />
              <ToggleVideoPublishingButton />
              <CallStatsButton />
              {isCallLive ? (
                <button
                  className="call-stop-button"
                  onClick={() => call?.stopLive()}
                >
                  Parar transmissão
                </button>
              ) : (
                <button
                  className="call-start-button"
                  onClick={() => call?.goLive()}
                >
                  Iniciar transmissão
                </button>
              )}
              {isCallLive && <div className="call-live-indicator">Ao vivo</div>}
              {!isCallLive && (
                <div className="call-close-indicator" onClick={endCall}>
                  Fechar
                </div>
              )}
            </div>
          </StreamTheme>
        )}
      </div>
    </div>
  );
};
