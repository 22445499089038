import axios from "axios";

const DEV = false //true;

const BASE_URL = DEV
  ? "http://localhost:3000/api/internal/v1"
  : "https://gql-staging.seidecor.com.br/api/internal/v1";

export async function getLive() {
  const response = await axios.get(`${BASE_URL}/live/new`);
  return response.data.key;
}

export async function defineAudience(liveId, audience, empresa, token) {
  const response = await axios.post(`${BASE_URL}/live/set-audience`, {
    liveId,
    audience,
    token,
    empresa
  }, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function notifyStatusChange(liveId, status, token) {
  const response = await axios.post(`${BASE_URL}/live/notify`, {
    liveId,
    status
  }, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}