import { useCallStateHooks } from "@stream-io/video-react-sdk";
import React from "react";

function Participants() {
  const { useRemoteParticipants } = useCallStateHooks();
  const remoteParticipants = useRemoteParticipants();
  console.log("remoteParticipants", remoteParticipants);

  return (
    <div className="participant-list">
      <div className="title">Participantes</div>
      {remoteParticipants.map((participant) => {
        const { connectionQuality, image, name, userIdm } = participant;
        return (
          <div key={participant.userId} className="participant-item">
            <img src={image} alt={name} className="participant-avatar" />
            <div className="participant-data">
              {participant.name}
              <ConnectionQuality quality={connectionQuality} />
            </div>
          </div>
        );
      })}
    </div>
  );
}

// connection quality component
function ConnectionQuality({ quality }) {
  /*
    se for 1 deve mostrar a qualidade como "Ruim"
    se for 2 deve mostrar a qualidade como "Regular"
    se for 3 deve mostrar a qualidade como "Boa"
    */
   let color;
    if (quality === 1) {
      color = "#e74c3c";
    } else if (quality === 2) {
      color = "#f5a623";
    } else if (quality === 3) {
      color = "#2ecc71";
    }
  return (
    <div className="connection-quality" style={{color}}>
     {'●'.repeat(quality)}
    </div>
  );
}

export default Participants;
