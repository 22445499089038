import React, { useEffect, useState } from "react";

function AudienceSelector({ segments = [], selectedSegments = [], toggle, confirm }) {
  const [seg, setSeg] = useState([]);

  useEffect(() => {
    // populate segments
    let nsegments = segments.map((segment) => {
      let selected = selectedSegments.some((s) => s.id === segment.id);
      return {
        id: segment.id,
        nome: segment.nome,
        selected,
      };
    });
    setSeg(nsegments);
  }, [segments, selectedSegments]);

  return (
    <div style={{ flex: 1 }}>
      <div className="sdc-title">Selecione os público da Live:</div>
      <div className="segment-container">
        {seg.map((segment) => {
          let selected = segment.selected;
          return (
            <div
              key={segment.id}
              onClick={() => toggle(segment)}
              className={selected ? "selected-segment sdc-text" : "segment sdc-text"}
            >
              {segment.nome}
            </div>
          );
        })}
      </div>
      <div className="button-container">
        <div className="button" onClick={confirm}>Avançar</div>
      </div>
    </div>
  );
}

export default AudienceSelector;
