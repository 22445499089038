import React, { useState } from "react";
import PubNub from "pubnub";
import { PubNubProvider } from "pubnub-react";
import { Chat, MessageList, MessageInput } from "@pubnub/react-chat-components";

function ChatComponent({ channel, user }) {
  console.log("user", user);
  const userId = user?.userId;
  const [pubnub] = useState(() => {
    const pubnub = new PubNub({
      publishKey: "pub-c-56bf7670-53c9-4c69-a118-07e474003bbb",
      subscribeKey: "sub-c-493ef912-c356-435a-8e21-b644b3fcba8f",
      userId: user.userId,
    });
    return pubnub;
  });
  const theme = "dark";
  if (!channel) return null;
  if (!userId) return null;
  if (!pubnub) return null;
  return (
    <PubNubProvider client={pubnub}>
      {/* PubNubProvider is a part of the PubNub React SDK and allows you to access PubNub instance
    in components down the tree. */}
      <Chat
        {...{ currentChannel: `live.${channel}`, theme: "dark" }}
        users={[
          {
            id: userId,
            name: user?.name,
            custom: {
              avatar: user?.image,
            },
          },
        ]}
        style={{flex: 1}}
      >
        {/* Chat is an obligatory state provider. It allows you to configure some common component
      options, like the current channel and the general theme for the app. */}
        <MessageList 
          fetchMessages={20}
          infiniteScroll
          showTime
          showSenderName
          showMessageStatus
        />
        <MessageInput
          style={{ borderRadius: 20 }}
          placeholder="Digite sua mensagem aqui..."
          senderInfo={true}
        />
      </Chat>
    </PubNubProvider>
  );
}

export default ChatComponent;
