import logo from "./logo.svg";
import "./App.css";
import { useCallback, useEffect } from "react";
import { defineAudience, getLive } from "./Api";
import { useState } from "react";
import QRCode from "react-qr-code";
import { initializeApp } from "firebase/app";
import { getDatabase, ref, onValue } from "firebase/database";
import {
  StreamCall,
  StreamVideo,
  StreamVideoClient,
  User,
} from "@stream-io/video-react-sdk";
import { LivestreamUI } from "./LiveStreamUI";
import AudienceSelector from "./Components/AudienceSelector";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const firebaseConfig = {
  apiKey: "AIzaSyC2Ac_AX31qqV0OXF1N1Q9o07tP88303Sw",
  authDomain: "seidecor-194519.firebaseapp.com",
  databaseURL: "https://seidecor-194519.firebaseio.com",
  projectId: "seidecor-194519",
  storageBucket: "seidecor-194519.appspot.com",
  messagingSenderId: "1073487642786",
  appId: "1:1073487642786:web:550c6023e9e8c3eb3597fb",
  measurementId: "G-D23J7V9QLR",
};

const STATUS = {
  setup: "setup",
  validating: "validating",
  setaudience: "set-audience",
  backstage: "backstage",
  broadcasting: "broadcasting",
  disconnected: "disconnected",
  error: "error",
};

console.log = ()=>{}
console.warn = ()=>{}

// Initialize Firebase
function App() {
  const app = initializeApp(firebaseConfig);
  const db = getDatabase();
  const [liveId, setLiveId] = useState(null);
  const [state, setState] = useState({
    status: STATUS.setup,
    message: "preparando...",
    user: null,
    callInfo: null,
    segments: [],
    selectedSegments: [],
    auth: null,
    empresa: null,
  });
  const [client, setClient] = useState(null);
  const [call, setCall] = useState(null);

  const {
    status,
    message,
    user,
    callInfo,
    selectedSegments,
    auth,
    segments,
    empresa,
  } = state;

  useEffect(() => {
    createLive();
    return () => {
      endLive();
    }
  }, []);

  function endLive(){
    if (call) {
      call.stopLive();
    }

    if(client){
      client.disconnect();
    }

  }

  function setURLBar(liveId) {
    // adds the liveId to the URL bar
    window.history.pushState({}, "Sei de Cor", `?liveId=${liveId}`);
  }

  // passo 1: cria uma sessão de live
  async function createLive(force = false) {
    // se tiver um liveId na URL, usa ele
    const urlParams = new URLSearchParams(window.location.search);
    let urlliveId = urlParams.get("liveId");
    let liveId;
    if (urlliveId && !force) {
      console.log("urlliveId", urlliveId);
      liveId = urlliveId;
    } else {
      console.log("Não tem liveId na URL");
      liveId = await getLive();
      // console.log("liveId", liveId);
    }
    //let liveId = "7101f482-3620-4ee5-b34f-092e1574d910";
    // console.log(
    //   `https://console.firebase.google.com/u/0/project/seidecor-194519/database/seidecor-194519/data/~2Flive_sessions/${liveId}`
    // );
    setLiveId(liveId);
    if (!urlliveId || force) {
      setURLBar(liveId);
    }
  }

  // passo 2: escuta validação de backend
  useEffect(() => {
    startListening();
  }, [liveId]);

  async function startListening() {
    if (!liveId) return;
    console.log("startListening", liveId);
    const liveRef = ref(db, `live_sessions/${liveId}`);
    try {
      onValue(liveRef, (snapshot) => {
        if (!snapshot.exists()) return;
        console.log("snapshot");
        console.log("snapshot", snapshot.val());
        const data = snapshot.val();
        console.log("data", data);
        const status = data.status;

        if(status === "finished"){
          createLive(true);
          return 
        }
        setState((s) => ({
          ...s,
          status: data.status,
          // message: data.message,
          user: data.user,
          callInfo: data.callInfo,
          auth: data.auth,
          segments: data.segments,
          empresa: data.empresa,
        }));
      });
    } catch (error) {
      console.error("error", error);
    }
  }

  useEffect(() => {
    console.log("status", status);
    switch (status) {
      case STATUS.validating:
        // passo 2: validar a sessão de live
        setState((s) => ({
          ...s,
          message: "Aguarde...\nEstamos configurando sua sessão",
        }));
        setupCall();
        break;
      case STATUS.backstage:
        break;
      default:
        break;
    }
  }, [status]);

  async function setupCall() {
    console.log("setupCall", callInfo);
    const { apiKey, token } = callInfo;
    const client = new StreamVideoClient({ apiKey, user, token });
    const call = await client.call("livestream", liveId);
    try {
      await call.join({ create: true });
    } catch (error) {
      console.log("error", error);
      setState((s) => ({
        ...s,
        status: STATUS.error,
        message:
          "Erro ao conectar com a sessão de live, você pode não ter permissão para transmitir nesta live.",
      }));
      return;
    }
    setClient(client);
    setCall(call);
    setState((s) => ({
      ...s,
      status: STATUS.backstage,
      message: "Estamos preparando sua transmissão",
    }));
  }

  // transform into callback
  const toggleSegment = useCallback(
    (segment) => {
      const index = selectedSegments.find((s) => s.id === segment.id);
      if (index) {
        const newSegments = selectedSegments.filter((s) => s.id !== segment.id);
        setState((s) => ({
          ...s,
          selectedSegments: newSegments,
        }));
      } else {
        const newSegments = [...selectedSegments, segment];
        setState((s) => ({
          ...s,
          selectedSegments: newSegments,
        }));
      }
    },
    [selectedSegments]
  );

  async function defineAudiencia() {
    if (selectedSegments.length === 0) {
      toast.error("Selecione pelo menos um público para continuar");
      return;
    }
    let response = await defineAudience(
      liveId,
      selectedSegments,
      empresa,
      auth
    );
    console.log("response", response);
    setState((s) => ({
      ...s,
      status: STATUS.backstage,
    }));
  }

  function copyToClipboard(text) {
    navigator.clipboard.writeText(text).then(
      function () {
        console.log("Async: Copying to clipboard was successful!");
        toast.info("Copiado para a área de transferência");
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
        toast.error("Erro ao copiar para a área de transferência");
      }
    );
  }

  switch (status) {
    case STATUS.error:
      return (
        <div className="App">
          <header className="App-header">
            <img
              src={
                "https://assets-global.website-files.com/62443b8315702250ce9e390d/62443c04718f404f79be83b6_logofullwhitesmall.png"
              }
              className="sdc-logo-container"
              alt="Sei de Cor"
            />
            <div className="error-message">{message}</div>
          </header>
        </div>
      );
      break;
    case STATUS.setaudience:
      return (
        <div className="App">
          <header className="App-header">
            <>
              <img
                src={
                  "https://assets-global.website-files.com/62443b8315702250ce9e390d/62443c04718f404f79be83b6_logofullwhitesmall.png"
                }
                className="sdc-logo-container"
                alt="Sei de Cor"
              />
              <div className="audience-selector">
                <div>
                  <img
                    src={user.image}
                    className="user-image"
                    alt={user.name}
                  />
                  <div className="user-name">{user.name}</div>
                  <hr />
                  <div className="horizontal-segment-pills">
                    {selectedSegments.map((segment) => {
                      return (
                        <div
                          key={segment.id}
                          className="horizontal-segment-pill"
                          onClick={() => toggleSegment(segment)}
                        >
                          {segment.nome}
                        </div>
                      );
                    })}
                  </div>
                  <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                  />
                </div>
                <AudienceSelector
                  segments={segments}
                  selectedSegments={selectedSegments}
                  toggle={toggleSegment}
                  confirm={defineAudiencia}
                />
              </div>
            </>
            <div className="uuid-text">ID: {liveId}</div>
          </header>
        </div>
      );

    case STATUS.backstage:
      return (
        <div className="App">
          <header className="App-header">
            <StreamVideo client={client}>
              <img
                src={
                  "https://assets-global.website-files.com/62443b8315702250ce9e390d/62443c04718f404f79be83b6_logofullwhitesmall.png"
                }
                className="sdc-logo-container-small"
                alt="Sei de Cor"
              />
              <StreamCall call={call}>
                <LivestreamUI token={auth} />
              </StreamCall>
            </StreamVideo>
            <></>
            <div className="uuid-text">ID: {liveId}</div>
          </header>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
        </div>
      );
      break;
    case STATUS.validating:
      return (
        <div className="App">
          <header className="App-header">
            <>
              <img
                src={
                  "https://assets-global.website-files.com/62443b8315702250ce9e390d/62443c04718f404f79be83b6_logofullwhitesmall.png"
                }
                className="sdc-logo-container"
                alt="Sei de Cor"
              />
              <img src={user.image} className="user-image" alt={user.name} />
              <div className="user-name">{user.name}</div>
              <div className="message-text">{message}</div>
              <div className="uuid-text">ID: {liveId}</div>
            </>
          </header>
        </div>
      );
      break;

    default:
      return (
        <div className="App">
          <header className="App-header">
            <>
              <img
                src={
                  "https://assets-global.website-files.com/62443b8315702250ce9e390d/62443c04718f404f79be83b6_logofullwhitesmall.png"
                }
                className="sdc-logo-container"
                alt="Sei de Cor"
              />
              <div className="qrcode-container">
                <QRCode value={`sdc://lid${liveId}`} />
              </div>
              <div className="qrcode-text">
                Escaneie o QRCode com o aplicativo Sei de Cor para configurar
                sua estação de Streaming
              </div>
              <div
                className="uuid-text"
                onClick={() => copyToClipboard(liveId)}
              >
                ID: {liveId}
              </div>
            </>
          </header>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
        </div>
      );
      break;
  }
}

export default App;
